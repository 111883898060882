import { useState, useContext, useEffect } from 'react';
import { PostMessageContext } from 'component/postMessage/PostMessageContext';
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Link,
  InputAdornment,
} from '@mui/material';
import PaginationControls from 'component/base/PaginationControls';
import NoResultsFound from 'component/base/NoResultsTableRow';
import { SearchRounded, Edit } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSites, SitePaginationState, useUserSites } from 'api/site';
import { useProfile } from 'api/profile';
import { formatDataSize } from 'utils/number';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import Flag from 'react-flagpack';
import { isInIframe } from 'utils/iframe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWordpress } from '@fortawesome/free-brands-svg-icons';
import {
  faAngleDown,
  faAngleUp,
  faComments,
  faLongArrowRight,
} from '@fortawesome/pro-regular-svg-icons';
import { ChangeLabelTooltip } from 'component/partial/ChangeLabelTooltip';
import { PendingSitesList } from './PendingSitesList';
import RocketLaptop from 'component/svg/rocket-laptop.svg?react';
import { loadDefaultPerPage } from 'utils/paginate';
import { SiteLockedPopover } from '../SiteLockedPopover';
import { getSiteLockedStatus } from 'utils/site';
import { RoleGuard } from 'component/base/RoleGuard';
import { formatDomainName } from 'utils/formatting';

export default function RowSkeleton({ showAtarimCell }: { readonly showAtarimCell: boolean }) {
  const rows = () => {
    return Array.from({ length: 3 }).map((v, i) => (
      <TableRow key={i}>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        {showAtarimCell ? (
          <TableCell>
            <Skeleton />
          </TableCell>
        ) : null}
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <RoleGuard roles={['billing_admin']} type="block">
          <TableCell>
            <Skeleton />
          </TableCell>
        </RoleGuard>
      </TableRow>
    ));
  };

  return <>{rows()}</>;
}
export function SiteList({ isWpSiteControl }: { readonly isWpSiteControl: boolean }) {
  const { sendMessage, getSdk } = useContext(PostMessageContext);
  const navigate = useNavigate();

  const [siteToChangeLabel, setSiteToChangeLabel] = useState<number | null>(null);
  const [pagination, setPagination] = useState<SitePaginationState>({
    perPage: loadDefaultPerPage(),
    activePageNumber: 1,
    filter: '',
    sortAttr: 'domain',
    sortOrder: 'asc',
  });
  const { data: profileData } = useProfile();
  const sdk = getSdk();
  const isPlanNotStarter = Boolean(
    profileData?.data.result?.package?.plan && profileData?.data.result.package.plan !== 'Starter'
  );
  const showAtarim = sdk === null && isPlanNotStarter;
  const sitesData = useSites(pagination, {
    enabled: !isWpSiteControl,
  });

  const userSitesData = useUserSites(pagination, {
    enabled: isWpSiteControl,
  });
  const sitesToUse = isWpSiteControl ? userSitesData : sitesData;
  const { data, status, refetch, isFetched } = sitesToUse;

  let searchTimeout: number | null = null;

  useEffect(() => {
    refetch();
  }, [
    pagination.activePageNumber,
    pagination.perPage,
    pagination.filter,
    pagination.sortAttr,
    pagination.sortOrder,
  ]);

  const { t } = useTranslation();

  const allSites = data?.data?.result || [];
  const sites = isWpSiteControl
    ? allSites.filter(site => site.status?.toLowerCase() === 'active')
    : allSites;
  const metadata = data?.data.metadata;

  const handlePaginationChange = (activePageNumber: number) => {
    setPagination({ ...pagination, activePageNumber });
  };

  const handlePerPage = (value: number) => {
    setPagination({
      ...pagination,
      activePageNumber: 1,
      perPage: value,
    });
  };

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = window.setTimeout(() => {
      setPagination({ ...pagination, activePageNumber: 1, filter: event.target.value });
    }, 300);
  };

  const renderTableRows = () => {
    if (status === 'pending') {
      return <RowSkeleton showAtarimCell={showAtarim} />;
    }

    return sites.map(site => {
      const location = site.location?.split(' - ') ?? '';
      const staticSite: boolean = site?.site_type === 1 || false;
      const lockedSite = getSiteLockedStatus(site);

      return (
        <TableRow key={site.id}>
          <TableCell>{formatDomainName(site.domain)}</TableCell>
          <TableCell>
            <Button variant="text" color="primary" onClick={() => setSiteToChangeLabel(site.id!)}>
              {site.label ? (
                <>
                  <Typography color="textPrimary">{site.label}</Typography>
                  <Box display="inline-block" marginLeft={1}>
                    <Edit />
                  </Box>
                </>
              ) : (
                <Typography color="textSecondary">{t('add_label')}</Typography>
              )}
            </Button>
          </TableCell>
          <TableCell>{formatDataSize(site.disk_usage)}</TableCell>
          <TableCell>
            <ErrorBoundary fallbackRender={() => location[0] ?? 'unknown'}>
              <Box data-testid="siteLocation">
                <Flag code={location[0] ?? 'US'} size="m" />{' '}
                {typeof location[1] === 'string' ? location[1] : site.location}
              </Box>
            </ErrorBoundary>
          </TableCell>
          {showAtarim && !staticSite ? (
            <TableCell>
              <Button
                onClick={() => {
                  if (isInIframe()) {
                    sendMessage(
                      JSON.stringify({
                        type: 'externalLink',
                        data: {
                          relative: false,
                          link: `https://app.atarim.io/fetching/?_from=rocket&url=https://${site.domain}`,
                        },
                      })
                    );
                  } else {
                    window.open(
                      `https://app.atarim.io/fetching/?_from=rocket&url=https://${site.domain}`
                    );
                  }
                }}
                variant="text"
                color="primary"
                startIcon={<FontAwesomeIcon icon={faComments} />}
                endIcon={<FontAwesomeIcon icon={faLongArrowRight} />}
              >
                {''}
              </Button>
            </TableCell>
          ) : null}
          {staticSite ? <TableCell>-</TableCell> : null}

          <RoleGuard roles={['billing_admin']} type="block">
            {!staticSite ? (
              <TableCell>
                <SiteLockedPopover isSiteLocked={lockedSite}>
                  <Button
                    onClick={() => {
                      if (isInIframe()) {
                        sendMessage(
                          JSON.stringify({
                            type: 'externalLink',
                            data: {
                              relative: true,
                              link: `/manage/${
                                site.id
                              }/wordpress?token=${window.sessionStorage.getItem('user_session')}`,
                            },
                          })
                        );
                      } else {
                        window.open(`/manage/${site.id}/wordpress`);
                      }
                    }}
                    variant="text"
                    color="primary"
                    startIcon={<FontAwesomeIcon icon={faWordpress} />}
                    endIcon={<FontAwesomeIcon icon={faLongArrowRight} />}
                    disabled={lockedSite}
                  >
                    {''}
                  </Button>
                </SiteLockedPopover>
              </TableCell>
            ) : (
              <TableCell>-</TableCell>
            )}
          </RoleGuard>
          <TableCell>
            <Link onClick={() => navigate(`/sites/${site.id}`)}>{t('manage')}</Link>
          </TableCell>
        </TableRow>
      );
    });
  };

  if (!pagination.filter && isFetched && !allSites.length) {
    return (
      <Card>
        <CardContent>
          <Box padding={5} alignContent="center" alignItems="center" textAlign="center">
            <RocketLaptop />
            <Box padding={1}>
              <Typography>{t('no_sites_message')}</Typography>
            </Box>
            {!isWpSiteControl && (
              <Box padding={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigate('/sites/create');
                  }}
                >
                  {t('create_site')}
                </Button>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    );
  }

  const opositeSortOrder = pagination.sortOrder === 'asc' ? 'desc' : 'asc';

  return (
    <>
      {siteToChangeLabel ? (
        <ChangeLabelTooltip
          key={siteToChangeLabel ?? 'none'}
          site={sites.find(site => site.id === siteToChangeLabel)!}
          onClose={() => setSiteToChangeLabel(null)}
        />
      ) : null}
      <Card>
        <CardHeader
          title={t('sites')}
          action={
            !isInIframe() &&
            !isWpSiteControl && (
              <RoleGuard roles={['billing_admin']} type="block">
                <Button
                  onClick={() => {
                    navigate('/sites/create');
                  }}
                  variant="contained"
                  color="primary"
                >
                  {t('create_site')}
                </Button>
              </RoleGuard>
            )
          }
        />
        <CardContent>
          <TableContainer>
            <TextField
              onChange={handleFilter}
              variant="outlined"
              placeholder={t('search_sites_label')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" disableTypography component="button">
                    <SearchRounded />
                  </InputAdornment>
                ),
              }}
            />
            <PendingSitesList
              sites={allSites}
              isWpSiteControl={isWpSiteControl}
              pagination={pagination}
              setPagination={setPagination}
              opositeSortOrder={opositeSortOrder}
              filteredSitesList={sites}
            />
            <Table aria-label="Site List Table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Box component="span">
                      <Link
                        onClick={() => {
                          setPagination({
                            ...pagination,
                            sortAttr: 'domain',
                            sortOrder: pagination.sortAttr === 'domain' ? opositeSortOrder : 'asc',
                          });
                        }}
                      >
                        {t('domain')}{' '}
                        {pagination.sortAttr === 'domain' ? (
                          <Box display="inline-block" marginLeft={1}>
                            <FontAwesomeIcon
                              icon={pagination.sortOrder === 'asc' ? faAngleUp : faAngleDown}
                            />
                          </Box>
                        ) : null}
                      </Link>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Link
                      onClick={() => {
                        setPagination({
                          ...pagination,
                          sortAttr: 'label',
                          sortOrder: pagination.sortAttr === 'label' ? opositeSortOrder : 'asc',
                        });
                      }}
                    >
                      {t('label')}
                      {pagination.sortAttr === 'label' ? (
                        <Box display="inline-block" marginLeft={1}>
                          <FontAwesomeIcon
                            icon={pagination.sortOrder === 'asc' ? faAngleUp : faAngleDown}
                          />
                        </Box>
                      ) : null}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      onClick={() => {
                        setPagination({
                          ...pagination,
                          sortAttr: 'disk_usage',
                          sortOrder:
                            pagination.sortAttr === 'disk_usage' ? opositeSortOrder : 'asc',
                        });
                      }}
                    >
                      {t('disk_usage')}
                      {pagination.sortAttr === 'disk_usage' ? (
                        <Box display="inline-block" marginLeft={1}>
                          <FontAwesomeIcon
                            icon={pagination.sortOrder === 'asc' ? faAngleUp : faAngleDown}
                          />
                        </Box>
                      ) : null}
                    </Link>
                  </TableCell>
                  <TableCell>{t('location')}</TableCell>
                  {showAtarim ? <TableCell>{t('atarim')}</TableCell> : null}
                  <RoleGuard roles={['billing_admin']} type="block">
                    <TableCell>{t('wp_admin')}</TableCell>
                  </RoleGuard>
                  <TableCell width={110}>{t('action')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pagination.filter !== '' && sites.length === 0 ? (
                  <NoResultsFound colSpan={showAtarim ? 7 : 6} />
                ) : (
                  renderTableRows()
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <PaginationControls
            totalRowCount={metadata?.total ?? sites.length}
            perPage={pagination.perPage}
            onPageChange={handlePaginationChange}
            onPerPageChange={handlePerPage}
          />
        </CardContent>
      </Card>
    </>
  );
}
